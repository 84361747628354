@font-face {
    font-family: 'Inter-Light';
    src: url('../assets/fonts/Inter/Inter-Light.ttf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Inter-Regular';
    src: url('../assets/fonts/Inter/Inter-Regular.ttf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('../assets/fonts/Inter/Inter-Medium.ttf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('../assets/fonts/Inter/Inter-SemiBold.ttf') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('../assets/fonts/Inter/Inter-Bold.ttf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: block;
}
